import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import { LeadContent } from '../../components/tools/';
import { EditLead } from '../../components/tools/';
import LeadService from '../../../service/tools/leads';
import { withRouter } from 'react-router';
import companyName from '../../../store/mobxStore/companyName';


function Leasds(props) {
    let service = new LeadService()
    const [state, setState] = useState({
        leads: [],   // list of leads
        keyword: '', // for filtering
        lead: null, //for editing lead
        showEditModal: false,
        new_comment: '',
        new_status: '',
        lead_url: '',

        alert: {
            show: false,
            message: '',
            varient: ''
        },
    })

    const handleEditAction = id => {
        let lead = state.leads.find(lead => lead.id === id);
        setState({
            lead,
            new_status: lead.status,
            showEditModal: true,
            leads:state.leads,
        })
    }

    const handleClose = () => {
        setState({
            showEditModal: false,
            leads:state.leads
        });
    }

    const keywordChange = (e) => {
        setState({
            keyword: e.target.value
        })
    }


    const getLeads = () => {
        service.getLeads(state.keyword)
            .then(res => {
                if (res.status) {
                    setState({
                        leads: res.data.leads,
                        lead_url: res.data.lead_url
                    })
                } else {
                    if (res.error.code === 1002) {
                        props.history.push('/logout');
                    } else if (res.error.code === 1057) {
                        props.history.push({
                            pathname: '/dashboard',
                            state: {
                                error: true,
                                message: 'permissionDenied'
                            }
                        })
                    }
                }
            });
    }

    const filterLeads = e => {
        e.preventDefault();
        getLeads();
    }
    const resetKeyword = () => {
        setState({
            ...state,
            keyword: '',
        });
        getLeads()
    }

    const newComment = e => {
        setState({
            ...state,
            new_comment: e.target.value
        })
    }

    const updateStatus = e => {
        setState({
            ...state,
            new_status: e.target.value
        })
    }

    const updateLead = e => {
        e.preventDefault();
        let formData = {
            comment: state.new_comment,
            status: state.new_status
        }

        service.updateLead(formData, state.lead.id).then(res => {
            if (res.status) {
                handleClose();
                getLeads();
                setState({
                    alert: {
                        show: true,
                        message: 'validation.leadUpdatedSuccessfully',
                        varient: 'success'
                    },
                })
                window.scrollTo(0, 0);
            } else {
                if (res.error.code === 1002) {
                    props.history.push('/logout');
                }
            }
        })
    }
    useEffect(() => {
        getLeads();

    }, []);
    const {t} = props;
      return (
        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.leads')}</title>
            </Helmet>
            <PageTitle 
                title="leads"  
                buttonOn={false}  
            />
            <LeadContent 
              keyword={state.keyword}
              leads={state.leads}
              editClick={handleEditAction}
              keywordChange={keywordChange}
              filterLeads={filterLeads}
              resetKeyword={resetKeyword}
              alert={state.alert}
              lead_url={state.lead_url}
            />

            <EditLead
              showEditModal={state.showEditModal}
              lead={state.lead}
              handleClose={handleClose}
              add_comment={state.new_comment}
              newComment={newComment}
              updateLead={updateLead}
              updateStatus={updateStatus}
            ></EditLead>
            
        </div>
    );
}
export default withTranslation()(withRouter(Leasds))