
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://demo8.infinitemlmdemo.com/boonnarach_oppasan/backoffice/api/";;
// const IMG_URL = "http://demo8.infinitemlmdemo.com/boonnarach_oppasan/uploads/images/";
// const BASE = "http://demo8.infinitemlmdemo.com/boonnarach_oppasan/";
// const API_KEY =localStorage.getItem('apiKey');
// const DEFAULT_KEY = '99552197-64bd-4f97-9958-9ee8925144ec'
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE, 
//    DEFAULT_KEY
// }


import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://xman4289.shop/backoffice/api/";;
const IMG_URL = "https://xman4289.shop/uploads/images/";
const BASE = "https://xman4289.shop/";
const API_KEY =localStorage.getItem('apiKey');
const DEFAULT_KEY = '0d1be819-5d4f-4276-8d2b-f0d6233be304'
export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE, 
   DEFAULT_KEY

}

